
import { Options, Vue } from 'vue-class-component';
import { FootwearEstimationClient, RawMaterialClient } from '@/services/Services';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class ComponentsOrigins extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() garmentIdentifier: string;
    @Prop() allMaterialCategories: string[];
    @Prop() allCountries: OM.TextIdentifier[];
    @Prop() compositionName: string;
    @Prop() compositionPercentage: number;
    @Prop() selectedMaterialIdentifier: string;
    @Prop() selectedCo2eEmissions: number;

    @Prop({
        default: ""
    }) selectedCountry: string;
    localSelectedCountry: string = "";
    
    @Prop({
        default: ""
    }) selectedMaterial: string;
    localSelectedMaterial: string = "";
    localCategoriesList: string[] = [];
    
    @Prop({
        default: false
    }) showCo2Emission: boolean;
    localShowCo2Emission: boolean = false;
    
    allAvailablesMaterials: OM.RawMaterialConfigurationVM[] = [];
    selectedMaterialCategory: string = "";
    rndMaterials: number = 0;
    co2eEmission: number = -1;
    
    created() {
        this.localShowCo2Emission = this.showCo2Emission;
        this.localSelectedCountry = this.selectedCountry;
        this.localSelectedMaterial = this.selectedMaterial;

        this.localCategoriesList.push("--None");
        this.allMaterialCategories.forEach(element => {
            this.localCategoriesList.push(element);
        });

        if(this.localSelectedMaterial) {
            RawMaterialClient.getMaterialCategory(this.selectedMaterialIdentifier)
            .then(x => {
                this.selectedMaterialCategory = x;
                this.co2eEmission = this.selectedCo2eEmissions;
                this.localShowCo2Emission = true;
            })
        }
        else {
            this.selectedMaterialCategory = "--None";
            this.co2eEmission = 0;
        }
    }

    @Watch('selectedMaterialCategory')
    updateMaterialsList() {
        if(!this.selectedMaterialCategory)
            return [];

        if(this.isNone) {
            this.localSelectedMaterial = "";
            this.localSelectedCountry = "";
            this.allAvailablesMaterials = [];
            return;
        }

        RawMaterialClient.getAllAvailableMaterialsFromGarmentAndCategory(this.garmentIdentifier, this.selectedMaterialCategory)
        .then(x => {
            this.allAvailablesMaterials = x;
            this.rndMaterials = Math.random();
        })
    }

    get isNone() {
        return this.selectedMaterialCategory.indexOf("--None") > -1
    }

    get materialsForList() {
        return this.allAvailablesMaterials.map(x => x.materialName);
    }

    calculateCo2eEmission() {
        if((!this.selectedMaterialCategory || !this.localSelectedCountry || !this.localSelectedMaterial) && !this.isNone)
            return;

        var model = new OM.FootwearEstimationUpdatePercentageCompositionValueVM();
        model.identifier = this.estimationIdentifier;
        model.name = this.compositionName;
        model.percentage = this.compositionPercentage;

        if(this.isNone) {
            model.selectedCountry = new OM.TextIdentifier();
            model.selectedMaterial = new OM.TextIdentifier();
        }
        else {
            model.selectedCountry = this.allCountries.filter(x => x.identifier == this.localSelectedCountry)[0]
            model.selectedMaterial = new OM.TextIdentifier();
            model.selectedMaterial.text = this.localSelectedMaterial;
            model.selectedMaterial.identifier = this.allAvailablesMaterials.filter(x => x.materialName == this.localSelectedMaterial)[0].identifier;
        }

        FootwearEstimationClient.updateMaterialCompositionValue(model)
        .then(x => {
            this.co2eEmission = x;
            this.localShowCo2Emission = true;
            this.$emit('updateList');
        })
    }
}
