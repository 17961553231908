
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, FootwearEstimationClient, GarmentClient } from '@/services/Services';
import ComponentsOrigins from './components/componentsOrigins.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ComponentsOrigins
    }
})
export default class ComponentsMaterial extends Vue {

    estimationIdentifier: string = "";

    footwearData: OM.FootwearData = new OM.FootwearData();
    footwearComposition: OM.SelectedComposition = new OM.SelectedComposition();

    allCountries: OM.TextIdentifier[] = [];
    allMaterialCategories: string[] = [];

    loaded: boolean = false;
    
    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }

    init() {
        Promise.all([
            FootwearEstimationClient.getEstimationFootwearData(this.estimationIdentifier),
            ElectricityEmissionClient.getCountries(false)
        ])
        .then(xs => {
            this.footwearData = xs[0];
            this.allCountries = xs[1];

            Promise.all([
                FootwearEstimationClient.getFootwearComposition(this.footwearData.garment.identifier, this.estimationIdentifier, false),
                GarmentClient.getAllAvailablesMaterialCategoriesFromConfig(this.footwearData.garment.identifier)
            ])
            .then(ys => {
                this.footwearComposition = ys[0];
                this.allMaterialCategories = ys[1];

                setTimeout(() => {
                    this.loaded = true;
                }, 100);
            })
        })
    }

    get formattedData() {
        let dataWithIndent = this.footwearComposition.percentageCompositionValues.map((item) => {
        let indentName = item.parentName ? `-- ${item.name}` : item.name;
            return {...item, indentName};
        });
        return dataWithIndent;
    }

    get isDisabled() {
        var done = this.footwearComposition.percentageCompositionValues.filter(x => x.selectedMaterial != null).length;
        return done < this.footwearComposition.percentageCompositionValues.filter(x => x.parentName != "").length;
    }
}
